<template>
  <v-data-table
    :headers="headers"
    :items="rows"
    :options.sync="pager"
    :loading="loading"
    loading-text="Loading the latest rankings..."
    :search="searchTerm"
    :footer-props="{itemsPerPageOptions: rowsPerPage}"
    :mobile-breakpoint="0"
    disable-sort
    class="og"
  >
    <template v-slot:progress>
      <v-progress-linear color="color3" indeterminate></v-progress-linear>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <v-row>
        <v-col class="shrink pa-0">
          <v-avatar
            size="46"
          >
            <img :src="item.profilePic" alt="~ :-)">
          </v-avatar>
        </v-col>
        <v-col class="grow pa-0 pl-1">
          <div class="font-weight-bold">{{item.name}} {{item.ageDivision === 'Adult' ? '' : item.ageDivision}}</div>
          <div class="font-weight-thin">{{item.cityState}}</div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.currentPoints`]="{ item }">
      {{item.currentPoints | fixed1}}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip left>
        <span>More on {{item.name}}</span>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="color3 color3Text--text" fab small @click.stop="gotoPlayer(item.playerProfileId)"><v-icon>fas fa-caret-right</v-icon></v-btn>
        </template>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import uniq from 'lodash.uniq'
import { api } from '@/classes/_URL'
import { ageDivision } from '@/classes/ProfileHelpers'

export default {
  props: ['players', 'searchTerm', 'loading', 'sbid', 'filter', 'male', 'preCalc'],
  data () {
    return {
      headers: [
        { text: 'Rank', value: 'rank', align: 'center', sortable: 'false', class: 'px-1' },
        { text: 'Player', value: 'name', align: 'left', sortable: 'false', class: 'px-1' },
        { text: 'Events', value: 'events', align: 'center', sortable: 'false', class: 'px-1' },
        { text: 'Points', value: 'currentPoints', align: 'center', sortable: 'false', class: 'px-1' },
        { text: '', value: 'actions', sortable: false, align: 'right', class: 'px-1' }
      ],
      pager: { sortDesc: false, itemsPerPage: 50 },
      rowsPerPage: [50, 100, 250, -1]
    }
  },
  computed: {
    rows () {
      if (!this.players) return []
      if (this.preCalc) {
        return this.players.map(m => {
          return {
            rank: m.genderRank,
            name: m.name,
            emails: m.emails,
            ageDivision: m.ageDivision,
            cityState: m.city && m.state ? `${m.city}, ${m.state}` : m.State,
            events: m.events,
            currentPoints: m.currentPoints,
            playerProfileId: m.playerProfileId,
            pic: `${api}/PlayerProfile/${m.playerProfileId}/profilePic` || (this.male ? 'https://vblimages.blob.core.windows.net/images/avatar-male.jpg' : 'https://vblimages.blob.core.windows.net/images/avatar-female.jpg'),
            profilePic: m.profilePic
          }
        })
      }

      let playerList = this.players

      if (this.filter && this.filter.length) {
        const ages = ['10U', '11U', '12U', '13U', '14U', '15U', '16U', '17U', '18U']
        const ageFilters = this.filter.filter(f => ages.includes(f))
        const otherFilters = this.filter.filter(f => !ages.includes(f))
        otherFilters.length && otherFilters.forEach(tag => {
          playerList = playerList.filter(f => f.tags.includes(tag))
        })
        if (ageFilters.length) {
          playerList = playerList.filter(f => ageFilters.includes(ageDivision(f.dob, f.gradYear)))
        }
      }

      const points = uniq(this.players.map(d => d.currentPoints)).sort((a, b) => { return b - a })
      // let points = [...new Set(playerList.map(d => +d.currentPoints))].sort().reverse()
      let rank = 1
      const results = []
      for (const point of points) {
        const players = playerList.filter(f => f.currentPoints === point)
        results.push(...players.map(m => {
          return {
            rank: rank,
            name: m.name,
            emails: m.emails,
            ageDivision: m.ageDivision,
            cityState: m.city && m.state ? `${m.city}, ${m.state}` : m.State,
            events: m.events,
            currentPoints: m.currentPoints,
            playerProfileId: m.playerProfileId,
            pic: `${api}/PlayerProfile/${m.playerProfileId}/profilePic` || (this.male ? 'https://vblimages.blob.core.windows.net/images/avatar-male.jpg' : 'https://vblimages.blob.core.windows.net/images/avatar-female.jpg'),
            profilePic: m.profilePic
          }
        }))
        rank += players.length
      }
      return results
    }
  },
  methods: {
    gotoPlayer (id) {
      this.$router.push({ name: 'player-profile', params: { playerProfileId: id, sbid: this.sbid } })
    }
  }
}
</script>

<style scoped>
.og,
.og >>> td {
  font-size: 0.8125rem !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
}
</style>
