var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"og",attrs:{"headers":_vm.headers,"items":_vm.rows,"options":_vm.pager,"loading":_vm.loading,"loading-text":"Loading the latest rankings...","search":_vm.searchTerm,"footer-props":{itemsPerPageOptions: _vm.rowsPerPage},"mobile-breakpoint":0,"disable-sort":""},on:{"update:options":function($event){_vm.pager=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"color3","indeterminate":""}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"shrink pa-0"},[_c('v-avatar',{attrs:{"size":"46"}},[_c('img',{attrs:{"src":item.profilePic,"alt":"~ :-)"}})])],1),_c('v-col',{staticClass:"grow pa-0 pl-1"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name)+" "+_vm._s(item.ageDivision === 'Adult' ? '' : item.ageDivision))]),_c('div',{staticClass:"font-weight-thin"},[_vm._v(_vm._s(item.cityState))])])],1)]}},{key:"item.currentPoints",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fixed1")(item.currentPoints))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"color3 color3Text--text","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.gotoPlayer(item.playerProfileId)}}},on),[_c('v-icon',[_vm._v("fas fa-caret-right")])],1)]}}],null,true)},[_c('span',[_vm._v("More on "+_vm._s(item.name))])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }